import React from 'react'
import "./ServicioPage.css"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheckToSlot} from '@fortawesome/free-solid-svg-icons'
import { faBookBookmark, faPenRuler, faFilePen } from '@fortawesome/free-solid-svg-icons'
import { data_pri_serv } from '../data/serv-pri'
import { useState, useEffect } from 'react'
import Footer from "../components/footer"
import { useUbiContext } from '../components/UserContext'
import videoServicios from "../assets/video_servicios_esc.webm"
import videoReclutamiento from "../assets/video_reclutamiento_esc.webm"
import nuestros_servicios2 from '../assets/nuestro_servicio2.JPG'
import nuestros_servicios3 from '../assets/nuestros_servicios3.JPG'
import nuestros_servicios5 from '../assets/nuestros_servicios5.JPG'
import {Tilt} from 'react-tilt'
import { Particulas } from '../components/Particulas'

const defaultOptions = {
	reverse:        false,  // reverse the tilt direction
	max:            35,     // max tilt rotation (degrees)
	perspective:    1000,   // Transform perspective, the lower the more extreme the tilt gets.
	scale:          1.1,    // 2 = 200%, 1.5 = 150%, etc..
	speed:          1000,   // Speed of the enter/exit transition
	transition:     true,   // Set a transition on enter/exit.
	axis:           null,   // What axis should be disabled. Can be X or Y.
	reset:          false,    // If the tilt effect has to be reset on exit.
	easing:         "cubic-bezier(.03,.98,.52,.99)",    // Easing on enter/exit.
}

function ServicioPage() {
  
  const {ubi,actualizarUbi}=useUbiContext()
  const [serv,setServ] = useState("")

  useEffect(()=>{
    if(ubi!== null){
      const targetElement = document.getElementById(ubi)
      if(targetElement){
        targetElement.scrollIntoView({behavior:"smooth"})
      }
      actualizarUbi(null)
    }
  },[])

  useEffect(() => {
    if(serv !== ""){
      const targetElement = document.getElementById(serv)
      if(targetElement){
        targetElement.scrollIntoView({behavior:"smooth"})
      }
      setServ("")
    }
  },[serv])
  
  const [contPri, setContPri] = useState(0)

  //State derivado
  const dataPri = data_pri_serv[contPri]
  
  return (
    <>
      <section className='relative bg-celeste py-2 px-5'>
        <Particulas/>
        <h2 className='text-azul-oscuro text-4xl text-center font-lexend font-bold py-5 relative'>NUESTROS SERVICIOS</h2>
        <main className='flex justify-center items-center gap-16 px-10 py-20 flex-wrap' id='serv1'>
          <div>
            <video
              id="myVideoServicio"
              controls
              className="h-[500px] m-auto rounded-lg shadow relative"
            >
              <source src={videoServicios} type='video/webm'/>
              Tu navegador no soporta el elemento del video
            </video>
          </div>
          <div className=''>
            <aside className='space-y-3 max-w-[400px] pb-10 border-azul-oscuro border-b-2 relative'>
              <span className='px-5 py-1 rounded-3xl bg-azul-oscuro text-amarillo font-lexend text-xl'>01</span>
              <h2 className='text-azul-oscuro font-lexend font-bold text-3xl'>Programas Estratégicos</h2>
              <p className='font-arimo text-azul-oscuro sm:text-xl text-lg'>Transforma tu empresa con soluciones personalizadas que combinan teoría y práctica. Ofrecemos cursos adaptados a tus necesidades, incluyendo talleres, charlas y capacitaciones, diseñados para impulsar el crecimiento sostenible y fortalecer la competitividad de tu organización.</p>
            </aside>
          </div>
        </main>
        <main className='flex justify-center items-center gap-16 px-10 py-20 flex-wrap relative' id='serv2'>
          <div className=''>
            <aside className='space-y-3 max-w-[400px] pb-10 border-azul-oscuro border-b-2'>
              <span className='px-5 py-1 rounded-3xl bg-azul-oscuro text-amarillo font-lexend text-xl'>02</span>
              <h2 className='text-azul-oscuro font-lexend font-bold text-3xl'>Diagnóstico Organizacional</h2>
              <p className='font-arimo text-azul-oscuro sm:text-xl text-lg'>Descubre el potencial oculto de tu empresa. Analizamos a fondo el clima y cultura organizacional, para conocer la estructura y procesos que nos permitan desarrollar una solución única para su empresa. ¡El primer paso hacia el éxito!</p>
            </aside>
          </div>
          <Tilt options={defaultOptions}>
            <img src={nuestros_servicios2} alt='Nuestros Servicios' className='paralelogramo w-[500px] max-w-[80%] aspect-square object-cover shadow-2xl ' />
          </Tilt>
        </main>
        <main className='flex justify-center items-center gap-16 px-10 py-20 flex-wrap relative' id='serv3'>
          <Tilt options={defaultOptions}>
            <img src={nuestros_servicios3} alt='Nuestros Servicios' className='paralelogramo w-[500px] max-w-[80%] aspect-square object-cover object-bottom shadow-2xl ' />
          </Tilt>
          <div className=''>
            <aside className='space-y-3 max-w-[400px] pb-10 border-azul-oscuro border-b-2'>
              <span className='px-5 py-1 rounded-3xl bg-azul-oscuro text-amarillo font-lexend text-xl'>03</span>
              <h2 className='text-azul-oscuro font-lexend font-bold text-3xl'>Mediación de Conflictos</h2>
              <p className='font-arimo text-azul-oscuro sm:text-xl text-lg'>Resuelve tensiones internas de manera efectiva. Actuamos como facilitadores imparciales para restaurar el diálogo, promover acuerdos y mejorar la dinámica laboral en tu organización.</p>
            </aside>
          </div>
        </main>
        <main className='flex justify-center items-center gap-16 px-10 py-20 flex-wrap relative' id='serv4'>
          <div className=''>
            <aside className='space-y-3 max-w-[400px] pb-10 border-azul-oscuro border-b-2'>
              <span className='px-5 py-1 rounded-3xl bg-azul-oscuro text-amarillo font-lexend text-xl'>04</span>
              <h2 className='text-azul-oscuro font-lexend font-bold text-3xl'>Reclutamiento y Selección de Personal</h2>
              <p className='font-arimo text-azul-oscuro sm:text-xl text-lg'>Encuentra al talento ideal para tu empresa. Nuestro enfoque estratégico y clínico, asegura la selección de los mejores profesionales, alineados con la cultura y objetivos de tu negocio.</p>
            </aside>
          </div>
          <div>
            <video
              id="myVideoServicio"
              controls
              className="h-[500px] m-auto rounded-lg shadow"
            >
              <source src={videoReclutamiento} type='video/webm'/>
              Tu navegador no soporta el elemento del video
            </video>
          </div>
        </main>
        <main className='flex justify-center items-center gap-16 px-10 py-20 flex-wrap relative' id='serv5'>
          <Tilt options={defaultOptions}>
            <img src={nuestros_servicios5} alt='Nuestros Servicios' className='paralelogramo w-[500px] max-w-[80%] aspect-square object-cover object-center shadow-2xl ' />
          </Tilt>
          <div className=''>
            <aside className='space-y-3 max-w-[400px] pb-10 border-azul-oscuro border-b-2'>
              <span className='px-5 py-1 rounded-3xl bg-azul-oscuro text-amarillo font-lexend text-xl'>05</span>
              <h2 className='text-azul-oscuro font-lexend font-bold text-3xl'>Consejería Laboral</h2>
              <p className='font-arimo text-azul-oscuro sm:text-xl text-lg'>Potencia el desarrollo profesional de tus colaboradores. Ofrecemos orientación personalizada para mejorar su desempeño, fomentar el crecimiento personal y fortalecer el ambiente laboral.</p>
            </aside>
          </div>
        </main>
      </section>
      <section className="sec-serv relative">
        <div>
        <section className='sec-serv-container'>
          <aside className={contPri === 0 ? "active-pri-serv":"def-pri-serv"} onClick={()=>setContPri(0)}>
            <FontAwesomeIcon icon={faCheckToSlot}/>
            <h3>¿Qué implica esta consultoría?</h3>
          </aside>
          <aside className={contPri === 1 ? "active-pri-serv":"def-pri-serv"} onClick={()=>setContPri(1)}>
            <FontAwesomeIcon icon={faBookBookmark} />
            <h3>Evaluación de clima y cultura</h3>
          </aside>
          <aside className={contPri === 2 ? "active-pri-serv":"def-pri-serv"} onClick={()=>setContPri(2)}>
            <FontAwesomeIcon icon={faPenRuler} />
            <h3>Diseño de Estrategias</h3>
          </aside>
          <aside className={contPri === 3 ? "active-pri-serv":"def-pri-serv"} onClick={()=>setContPri(3)}>
            <FontAwesomeIcon icon={faFilePen} />
            <h3>Desarrollo de programas de bienestar laboral</h3>
          </aside>
        </section>
        </div>
        <section className='sec-serv-text' id='servicios'>
          <h2>{dataPri.titulo}</h2>
          {dataPri.lista ? 
          (<ul>
            {dataPri.des.map((e, index)=>{
              return (
               <li key={index}>{e}</li> 
              )
            })}
          </ul>)
          :
          (<>
            {dataPri.des.map((e, index)=>{
              return (
               <p key={index}>{e}</p>
              )
            })}
          </>)}
        </section>
      </section>
      
      <Footer/>
    </>
  )
}

export default ServicioPage