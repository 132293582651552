import "./WhIcon.css"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons"

export default function WhIcon(){

    return (
        <a className="fixed bottom-7 right-7 border-amarillo border-2 bg-azul-oscuro text-4xl flex justify-center items-center w-20 h-20 rounded-xl bg-opacity-50 backdrop-blur-sm z-40 text-amarillo cursor-pointer" href="https://api.whatsapp.com/send?phone=51920188134&text=Hola%2C%20estoy%20interesado%20en%20los%20servicios%20de%20ESC" target="_blank" rel="noreferrer" aria-label="Contacto de whatsapp">
            <FontAwesomeIcon icon={faWhatsapp} bounce />
        </a>
    )
}