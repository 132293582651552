import React from 'react'
import videoServicios from "../assets/video_servicios_esc.webm"
import { faBuildingShield, faBullhorn, faClipboard, faStar } from '@fortawesome/free-solid-svg-icons'
import { faPersonCircleCheck } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import t1 from "../assets/valeria_arbulu_esc.jpeg"
import t2 from "../assets/rodrigo_scerpella_esc.jpeg"
import t3 from "../assets/araceli_esc.jpeg"
import Footer from '../components/footer'

const listaServicios = [
    {
        name:"Programas Estratégicos",
        content:"Ofrecemos cursos adaptados a tus necesidades, incluyendo talleres, charlas y capacitaciones, diseñados para impulsar el crecimiento sostenible y fortalecer la competitividad de tu organización.",
        icon:faBuildingShield
    },
    {
        name:"Diagnóstico Organizacional",
        content:"Analizamos a fondo el clima y cultura organizacional, para conocer la estructura y procesos que nos permitan desarrollar una solución única para su empresa.",
        icon:faClipboard
    },
    {
        name:"Mediación de Conflictos",
        content:"Actuamos como facilitadores imparciales para restaurar el diálogo, promover acuerdos y mejorar la dinámica laboral en tu organización.",
        icon:faBuildingShield
    },
    {
        name:"Reclutamiento y Selección de Personal",
        content:"Nuestro enfoque estratégico y clínico, asegura la selección de los mejores profesionales, alineados con la cultura y objetivos de tu negocio.",
        icon:faPersonCircleCheck
    },
    {
        name:"Consejería Laboral",
        content:"Ofrecemos orientación personalizada para mejorar su desempeño, fomentar el crecimiento personal y fortalecer el ambiente laboral.",
        icon:faBullhorn
    }
]

const testimonios = [
    {
        name:"Valeria Arbulú",
        job:"Gerente de Recursos Humanos",
        enterprise:"Sierra Muebles",
        img:t1,
        comment:"Me pareció un servicio bastante completo porque se dedicaron de lleno a cada uno de los colaboradores. Les dieron el tiempo que necesitaban y les abrieron el espacio al diálogo sin limitaciones."
    },
    {
        name:"Rodrigo Scerpella",
        job:"Business developer",
        enterprise:"Pacific",
        img:t2,
        comment:"El principal cambio que veo es que las personas automáticamente entendieron que todo el trabajo que realizamos con la consultora es para su beneficio profesional y personal."
    },
    {
        name:"Araceli",
        job:"Supervisora de laboratorio",
        enterprise:"Pacific",
        img:t3,
        comment:"Su trabajo es muy personalizado, efectivo y profesional. Toman a la empresa como un socio aliado y se involucran en cada paso del proceso. Además, consideran y valoran a cada uno de los colaboradores de forma integral."
    }
]

function LandingPage() {
  return (
    <div className='overflow-x-hidden'>
        {/* PROBLEM */}
        <main className='w-screen md:h-dvh flex justify-center items-center relative overflow-hidden'>
            <div className='absolute size-full rotate-45 bg-azul-claro rounded-3xl z-10 top-0 left-0 -translate-x-1/2 -translate-y-1/2'/>
            <div className='absolute size-full rotate-45 bg-amarillo rounded-3xl z-10 top-full left-full -translate-x-1/2 -translate-y-1/2'/>
            <section className='flex flex-wrap gap-10 lg:justify-between justify-center items-center w-[1500px] max-w-full p-10 relative z-20'>
                <div className='max-w-[450px] space-y-5'>
                    <h1 className='font-lexend-bold sm:text-6xl text-4xl text-azul-oscuro'>¿<span className='text-red-500 '>PROBLEMAS</span> CON TUS EMPLEADOS?</h1>
                    <h1 className='font-lexend-bold sm:text-6xl text-4xl text-azul-oscuro'>¿<span className='text-red-500'>FALLA</span> RR.HH?</h1>
                    <p className='font-arimo text-xl text-azul-oscuro'>
                    Potenciamos el clima y cultura que tu empresa necesita, a través:
                    </p>
                    <ul className='font-arimo text-azul-oscuro text-xl list-disc ms-5 lowercase'>
                        <li>PROGRAMAS ESTRATÉGICOS</li>
                        <li>DIAGNÓSTICO ORGANIZACIONAL</li>
                        <li>MEDIACIÓN DE CONFLICTOS</li>
                        <li>RECLUTAMIENTO Y SELECCIÓN DE PERSONAL</li>
                        <li>CONSEJERÍA LABORAL</li>
                    </ul>
                    <a href='https://api.whatsapp.com/send?phone=51920188134&text=Hola%2C%20estoy%20interesado%20en%20los%20servicios%20de%20ESC' target='_blank' rel='noopener noreferrer' className='font-lexend text-azul-oscuro bg-amarillo text-2xl px-5 py-2 block w-fit rounded-md transition-all ease-in-out duration-300 hover:bg-azul-oscuro hover:text-amarillo '>CONTÁCTANOS</a>
                </div>
                <div className='flex-none'>
                    <video
                        id="myVideoServicio"
                        controls
                        className="h-[500px] m-auto rounded-lg shadow relative"
                    >
                        <source src={videoServicios} type='video/webm'/>
                        Tu navegador no soporta el elemento del video
                    </video>
                </div>
            </section>
        </main>
        {/* SERVICIOS */}
        <section className='space-y-5 pt-10'>
            <h2 className='font-lexend-bold text-4xl text-center mt-5 text-azul-oscuro px-5'>Conoce nuestros servicios</h2>
            <p className='font-arimo leading-snug text-xl text-azul-oscuro opacity-80 px-5 text-center max-w-[500px] mx-auto'>ESC ofrece servicios dedicados a ayudar a todas las empresas en sus ámbitos laborales, con el fin de hacer los procesos más eficientes y productivos.</p>
            <aside className='p-10 flex justify-center items-stretch gap-10 flex-wrap'>
                {listaServicios.map((val,i) => (
                    <div key={i} className='w-96 h-96 max-w-full shadow-md  rounded-lg overflow-hidden space-y-5'>
                        <div className='w-20 aspect-square flex justify-center items-center p-5 bg-amarillo text-white text-4xl'>
                            <FontAwesomeIcon icon={val.icon} className=''/>
                        </div>
                        <div className='w-full p-5 space-y-5'>
                            <h3 className='font-lexend text-azul-oscuro text-3xl'>{val.name}</h3>
                            <p className='font-arimo text-lg text-azul-oscuro opacity-90'>
                                {val.content}
                            </p>
                        </div>
                    </div>
                ))}
            </aside>
        </section>
        <section className='py-10 space-y-5'>
            <h2 className='font-lexend-bold text-4xl text-center mt-5 text-azul-oscuro px-5'>¿Qué dicen de nosotros?</h2>
            <p className='font-arimo leading-snug text-xl text-azul-oscuro opacity-80 px-5 text-center max-w-[500px] mx-auto'>Hemos trabajado de manera ejemplar con nuestros clientes de tal manera que la eficiencia y productiviidad de sus empresas a aumentado con creces.</p>
            <section className='p-5 flex justify-center items-stretch gap-10 flex-wrap'>
                {testimonios.map((val,i) => (
                    <div className='w-96 shadow-md rounded-lg py-5 flex flex-col justify-between border-2 border-dashed border-celeste'>
                        <div className='flex items-center justify-start gap-5 p-3'>
                            <img src={val.img} alt={val.name} className='w-20 aspect-square p-1 border-dotted border-2 border-amarillo rounded-full' />
                            <div>
                                <h3 className='font-lexend-bold text-azul-oscuro'>{val.name}</h3>
                                <p className='font-lexend text-azul-oscuro'>{val.job} <br/> <span className='text-azul-claro'>{val.enterprise}</span></p>
                            </div>
                        </div>
                        <p className='p-5 text-azul-oscuro font-arimo'>
                            {val.comment}
                        </p>
                        <div className='text-amarillo px-5'>
                            {[1,2,3,4,5].map((val,i) => (
                                <FontAwesomeIcon icon={faStar} />
                            ))}
                        </div>
                    </div>
                ))}
            </section>
        </section>
        <Footer/>
    </div>
  )
}

export default LandingPage