import './App.css';
import {BrowserRouter, Route, Routes, Navigate} from 'react-router-dom'
import InicioPage from './pages/InicioPage';
import Nav from './components/Nav';
import ServicioPage from "./pages/ServicioPage"
import NuestroEquipo from './pages/NuestroEquipo';
import PorqueESC from "./pages/PorqueESC";
import {UserContext} from "./components/UserContext"
import WhIcon from "./components/WhIcon"
import BlogPage from "./pages/BlogPage"
import BlogContent from "./pages/BlogContent"
import { blogs } from './data/blogs';
import LandingPage from './pages/LandingPage';

function App() {
  return (
    <UserContext>
    <BrowserRouter>
    <WhIcon/>
    <Nav/>
    <Routes>
      <Route path='/' element={<Navigate to="/inicio"></Navigate>}>
      </Route>
      <Route path='inicio' element={<InicioPage/>}></Route>
      <Route path='servicio' element={<ServicioPage/>}></Route>
      <Route path='nuestro-equipo' element={<NuestroEquipo/>}></Route>
      <Route path='porque-esc' element={<PorqueESC/>}></Route>
      <Route path='landing' element={<LandingPage/>}></Route>
      <Route path='blog' element={<BlogPage/>}></Route>
      {blogs.map((e,index) => <Route key={index} path={e.url} element={<BlogContent blog={e}/>}></Route>)}
      <Route path='*' element={<Navigate to="/inicio"></Navigate>}/>
    </Routes>
    </BrowserRouter>
    </UserContext>
  );
}

export default App;
