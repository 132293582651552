import React from 'react'
import "./InicioPage.css"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBuildingShield, faBullhorn, faClipboard } from '@fortawesome/free-solid-svg-icons'
import { faPersonCircleCheck } from '@fortawesome/free-solid-svg-icons'
import { faStar } from '@fortawesome/free-regular-svg-icons'
import { faPeopleGroup } from '@fortawesome/free-solid-svg-icons'
import Footer from '../components/footer'
import { useNavigate } from 'react-router-dom'
import { useUbiContext } from '../components/UserContext'
import t1 from "../assets/valeria_arbulu_esc.jpeg"
import t2 from "../assets/rodrigo_scerpella_esc.jpeg"
import t3 from "../assets/araceli_esc.jpeg"
import ilumina from "../assets/ILUMINA_COL.png"
import adex from "../assets/ADEX.png"
import ccll_contructora from "../assets/ccl_camara.png"
import videoValeria from "../assets/videob7_esc_1.mp4"
import videoRodrigo from "../assets/videob6_esc_1.mp4"
import FormVoluntario from '../components/FormVoluntario'
import cita1 from '../assets/cita_esc1_v2.JPG'
import cita2 from '../assets/cita_esc2_v2.JPG'
import cita3 from '../assets/cita_esc3_v2.JPG'
import Swal from 'sweetalert2'
import nuestros_servicios from '../assets/nuestros_servicios.JPG'
import nuestros_servicios2 from '../assets/nuestro_servicio2.JPG'
import nuestros_servicios3 from '../assets/nuestros_servicios3.JPG'
import nuestros_servicios4 from '../assets/nuestros_servicios4.JPG'
import nuestros_servicios5 from '../assets/nuestros_servicios5.JPG'

const listaServicios = [
    {
        name:"Programas Estratégicos",
        content:"Transforma tu empresa con soluciones personalizadas que combinan teoría y práctica. Ofrecemos cursos adaptados a tus necesidades, incluyendo talleres, charlas y capacitaciones, diseñados para impulsar el crecimiento sostenible y fortalecer la competitividad de tu organización.",
        img:nuestros_servicios,
        icon:faBuildingShield
    },
    {
        name:"Diagnóstico Organizacional",
        content:"Descubre el potencial oculto de tu empresa. Analizamos a fondo el clima y cultura organizacional, para conocer la estructura y procesos que nos permitan desarrollar una solución única para su empresa. ¡El primer paso hacia el éxito!",
        img:nuestros_servicios2,
        icon:faClipboard
    },
    {
        name:"Mediación de Conflictos",
        content:"Resuelve tensiones internas de manera efectiva. Actuamos como facilitadores imparciales para restaurar el diálogo, promover acuerdos y mejorar la dinámica laboral en tu organización.",
        img:nuestros_servicios3,
        icon:faBuildingShield
    },
    {
        name:"Reclutamiento y Selección de Personal",
        content:"Encuentra al talento ideal para tu empresa. Nuestro enfoque estratégico y clínico, asegura la selección de los mejores profesionales, alineados con la cultura y objetivos de tu negocio.",
        img:nuestros_servicios4,
        icon:faPersonCircleCheck
    },
    {
        name:"Consejería Laboral",
        content:"Potencia el desarrollo profesional de tus colaboradores. Ofrecemos orientación personalizada para mejorar su desempeño, fomentar el crecimiento personal y fortalecer el ambiente laboral.",
        img:nuestros_servicios5,
        icon:faBullhorn
    }
]

function InicioPage() {

    const {actualizarUbi} = useUbiContext()

    const navigate = useNavigate()

    const navServicios = (link)=>{
        actualizarUbi(link)
        navigate("/servicio")
    }

  const showVideoPopupValeria = () => {
    Swal.fire({
      html: `
        <video
            id="myVideo"
            controls
            className="h-96 m-auto rounded-lg shadow"
        >
                        
            <source src=${videoValeria} type="video/mp4" />
            Your browser does not support the video tag.
        </video>
      `,
      showCloseButton: true,
      showCancelButton: false,
      focusConfirm: false,
      confirmButtonText: 'Cerrar',
    });
  };

  const showVideoPopupRodrigo = () => {
    Swal.fire({
      html: `
        <video
            id="myVideo"
            controls
            className="h-96 m-auto rounded-lg shadow"
        >
                        
            <source src=${videoRodrigo} type="video/mp4" />
            Your browser does not support the video tag.
        </video>
      `,
      showCloseButton: true,
      showCancelButton: false,
      focusConfirm: false,
      confirmButtonText: 'Cerrar',
    });
  };

  return (
    <div id='inicio'>
    <section className='pri-inicio'>
        <div>
            <h1>Invertir en la cultura laboral es invertir en el futuro de tu empresa</h1>
            <p>¡Hagamos que cada día de trabajo sea inspirador y productivo!</p>
            <a target='_blank' rel='noreferrer noopener' href='https://bit.ly/esc-escucha-soluciona-crece'>Cotización Gratuita</a>
        </div>
    </section>
    <section className='bg-white sm:px-10 px-5 py-20 flex justify-center items-center'>
        <main className='grid 2xl:grid-cols-3 lg:grid-cols-2 grid-cols-1 gap-10 justify-stretch items-stretch '>
            {listaServicios.map((val,i) => (
                <section key={i} className='lg:w-96 w-full rounded-2xl border-2 border-celeste flex lg:flex-col flex-row shadow-sm overflow-hidden'>
                    <div className='lg:w-full sm:w-1/2 w-0  aspect-square object-cover relative'>
                        <img alt={val.name} src={val.img} className='size-full absolute inset-0 object-cover object-center  z-10'/>
                        <span className='rounded-full text-5xl text-celeste hidden justify-center items-center p-5 bg-white shadow-sm -translate-x-1/2 -translate-y-1/2 top-full left-1/2 absolute z-20 lg:flex'>
                            <FontAwesomeIcon icon={val.icon} />
                        </span>
                    </div>
                    <div className='text-azul-oscuro text-center pb-5 py-16 gap-5 flex-1 flex flex-col justify-between'>
                        <FontAwesomeIcon icon={val.icon} className='text-3xl lg:hidden block' />
                        <h2 className='font-lexend font-bold text-2xl px-5'>{val.name}</h2>
                        <p className='line-clamp-3 leading-relaxed opacity-50 font-arimo text-base px-10'>{val.content}</p>
                        <button className='w-fit mx-auto bg-azul-oscuro bg-opacity-30 px-3 py-2 transition-all ease-in-out duration-300 hover:bg-opacity-100 hover:text-amarillo' onClick={() => navServicios(`serv${i+1}`)}>Ver más</button>
                    </div>
                </section>
            ))}
        </main>
    </section>
    <section className='nuestra-mision nm1'>
        <section>
            <h2>«Nuestra Misión»</h2>
            <p>«Potenciamos el clima y cultura que tu empresa necesita, a través de soluciones  que se enfoquen en la salud mental»</p>
        </section>
        <aside></aside>
    </section>
    <h2 className='testimionios-tit'>Testimonios</h2>
    <aside className='testimonios'>
        <section>
            <div>
            <img src={t1}  alt='Valeria Arbulú'/>
            
                <h2>Valeria Arbulú <br></br>
                    <span>Gerente de Recursos Humanos - Sierra Muebles</span>
                </h2>
                <p>Me pareció un servicio bastante completo porque se dedicaron de lleno a cada uno de los colaboradores. Les dieron el tiempo que necesitaban y les abrieron el espacio al diálogo sin limitaciones. Además, algo que valoro mucho por encima de otras consultoras es el criterio clínico que aportan en sus evaluaciones y durante todo su enfoque, asegurando el cuidado integral de cada colaborador.</p>
                <button className='w-3/4 text-center py-2 bg-amarillo texto texto-azul rounded-lg shadow-lg my-2 m-auto transition-all hover:scale-95' onClick={showVideoPopupValeria}>
                    Ver videos
                </button>
            </div>
        </section>
        <section>
            <div>
            <img src={t2}  alt='Rodrigo Scerpella'/>
            
                <h2>Rodrigo Scerpella <br></br>
                    <span>Business developer - Pacific</span>
                </h2>
                <p>El principal cambio que veo es que las personas automáticamente entendieron que todo el trabajo que realizamos con la consultora es para su beneficio profesional y personal. Se sienten más valorados, reconocidos, escuchados y considerados. El ambiente ya empezó a sentirse diferente y aún ni siquiera hemos empezado las capacitaciones específicas. Estos primeros cambios ya nos hacen ver a futuro con mucho entusiasmo.</p>
                <button className='w-3/4 text-center py-2 bg-amarillo texto texto-azul rounded-lg shadow-lg my-2 m-auto transition-all hover:scale-95' onClick={showVideoPopupRodrigo}>Ver video</button>
            </div>
        </section>
        <section>
            <div>
            <img src={t3}  alt='Araceli'/>
            
                <h2>Araceli <br></br>
                    <span>Supervisora de laboratorio - Pacific</span>
                </h2>
                <p>Supervisora de laboratorio
                Recomiendo mucho a ESC. Su trabajo es muy personalizado, efectivo y profesional. Toman a la empresa como un socio aliado y se involucran en cada paso del proceso. Además, consideran y valoran a cada uno de los colaboradores de forma integral.</p>
            </div>
        </section>
    </aside>
    <aside className='colaboraciones'>
        <h2>Alianzas y Convenios</h2>
        <section>
            <div>
                <img src={ilumina} alt='ilumina'/>
                <aside>TU ACCESO A LA SALUD MENTAL</aside>
            </div>
            <div><img src={adex} alt='adex'/></div>
            <div><img src={ccll_contructora} alt='ccll_contructora'/></div>
        </section>
    </aside>
    <section className='opc-inicio'>
        <section>
            <FontAwesomeIcon icon={faPersonCircleCheck} className='icon-inicio'/>
            <p>Ayuda a atraer nuevos empleados y retener a los más destacados.</p>
            <a target='_blank' rel='noreferrer noopener' href='https://bit.ly/esc-escucha-soluciona-crece'>Infórmate</a>
        </section>
        <section>
            <FontAwesomeIcon icon={faStar} className='icon-inicio'/>
            <p>Facilita el comrpomiso y la retención de la fuerza de trabajo.</p>
            <a target='_blank' rel='noreferrer noopener' href='https://bit.ly/esc-escucha-soluciona-crece'>Capacítate</a>
        </section>
        <section>
            <FontAwesomeIcon icon={faPeopleGroup} className='icon-inicio'/>
            <p>Los empleados mejoran su rendimiento, son más eficientes y productivos.</p>
            <a target='_blank' rel='noreferrer noopener' href='https://bit.ly/esc-escucha-soluciona-crece'>Conócenos</a>
        </section>
    </section>
    <section className='bg-celeste px-5 py-10 flex justify-around items-center gap-8 flex-wrap'>
        <div className='max-w-[500px] flex flex-col gap-4 justify-center items-center'>
            <h2 className='text-azul-oscuro font-lexend text-4xl font-bold'>Agenda una cita</h2>
            <FormVoluntario/>
        </div>
        <div className='p-5 relative'>
            <img src={cita1} alt='cita ESC' className='sm:w-[400px] sm:h-[600px] w-[300px] h-[450px] rounded-lg shadow-lg'/>
            <img src={cita2} alt='cita ESC' className='absolute sm:top-[-20px] sm:left-[-20px] -top-2 left-1 w-[250px] rounded-lg shadow-2xl'/>
            <img src={cita3} alt='cita ESC' className='w-[150px] absolute bottom-0 right-0 rounded-lg shadow-2xl' />
        </div>
    </section>
    <Footer/>
    </div>
  )
}

export default InicioPage
