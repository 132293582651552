export default function FormVoluntario() {
  return (
    <form className="p-3 bg-opacity-30 rounded-xl space-y-3">
            <section className="flex sm:flex-row flex-col gap-3 flex-wrap">
                <input className="shadow-md outline-none bg-slate-50 px-5 py-3 text-black rounded-xl text-sm flex-1" placeholder="Nombre" type="text" id="name"/>
                <input className="shadow-md outline-none bg-slate-50 px-5 py-3 text-black rounded-xl text-sm flex-1 " type="text" placeholder="Apellido" id="lastname"/>
            </section>
            <input className="shadow-md outline-none bg-slate-50 px-5 py-3 rounded-xl text-sm w-full text-black" type="email" id="correo" placeholder="Correo Electrónico"/>
            <input className="shadow-md outline-none bg-slate-50 px-5 py-3 rounded-xl text-sm w-full text-black" type="text" id="telefono" placeholder="Teléfono"/>
            <input className="shadow-md outline-none bg-slate-50 px-5 py-3 rounded-xl text-sm w-full text-black" type="text" id="asunto" placeholder="Asunto"/>
            <textarea className="shadow-md outline-none bg-slate-50 px-5 py-3 rounded-xl text-sm w-full text-black resize-none" type="textarea" placeholder="Mensaje" id="mensaje" rows={3} />
            <br></br>
            <input type="submit" value={"Enviar"} className="shadow-md text-sm subtexto bg-amarillo text-azul-oscuro transition-all px-4 py-2 rounded-lg mt-3 cursor-pointer hover:shadow-none" onClick={(e)=>e.preventDefault()}/>
        </form>
  )
}
