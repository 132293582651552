import React from 'react'
import "./footer.css"
import esc_logo_largo from "../assets/esc-escucha-soluciona-crece-largo.svg"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faInstagram, faLinkedinIn } from '@fortawesome/free-brands-svg-icons'

function Footer() {

  return (
    <footer className='flex flex-wrap justify-around items-center gap-5 bg-azul-oscuro text-amarillo px-20 py-10 relative'>
        <img src={esc_logo_largo} alt='ESC Escucha Soluciona Crece' className='w-64 h-32 object-cover'/>
        <section className='flex gap-5 justify-center items-center flex-wrap'>
            <p>
            <b>Dirección:</b>
            <br></br>
            Jr. Cosmos 144, Santiago de Surco
            </p>
            <p>
            <b>Teléfono:</b>
            <br></br>
            +51 920 188 134
            </p>
            <p>
            <b>Correo:</b>
            <br></br>
            contacto@escconsultora.com
            </p>
            <div className='flex text-4xl gap-5'>
              <a href="https://www.instagram.com/esc.consultora.recursoshumanos?igsh=MW9lM3N6MWgwazV3dw==" target='_blank' rel='noreferrer noopener'>
                <FontAwesomeIcon icon={faInstagram}/>
              </a>
              <a href="https://www.linkedin.com/company/esc-consultora/" target='_blank' rel='noreferrer noopener'>
                <FontAwesomeIcon icon={faLinkedinIn}/>
              </a>
            </div>
        </section>
    </footer>
  )
}

export default Footer
